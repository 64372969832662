import * as React from "react";
import { Parallax } from "react-parallax";

import image from "../images/team-meet.jpg";
import davidPhoto from "../images/david.png";
import davidHeadshot from "../images/david-headshot.png";
import andeHeadshot from "../images/ande-headshot.png";
import jonPhoto from "../images/jon-burke.png";
import jonHeadshot from "../images/jon-headshot.jpg";
import jamesHeadshot from "../images/james-headshot.jpg";
import charlesHeadshot from "../images/charles-headshot.png";
import andePhoto from "../images/ande-noktes.png";
import craigPhoto from "../images/craig-nazzaro.png";
import craigHeadshot from "../images/craig-headshot.png";
import charlesPhoto from "../images/charles-wagner.png";
import jeffreyPhoto from "../images/jeffrey-atkisson.png";
import jeffreyHeadshot from "../images/jeffrey-headshot.png";

import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";
import { Col, Container, Modal, Row } from "react-bootstrap";

const LoanOfficers = () => {
  const [modalOpen, setModalOpen] = React.useState<string>("");

  const lib: {
    id: string;
    name: string;
    title: string;
    photo: string;
    bio: string;
  }[] = [
    {
      id: "davidGe",
      name: "David Ge",
      title: "CEO",
      photo: davidHeadshot,
      bio: "David has a nontraditional background. He started his career in professional gaming and attended college in his late twenties. Afterward, he started his investment banking career where he focused on M&A for banks and financing companies. He eventually became a C-level executive in the telecom sector, where he experienced the current problems in the bank lending cycle. After being a CFO trying to finance the future growth of a 300 person company and then realizing the inefficiencies in the lending cycle, David set on a journey. A journey to improve the lending cycle for businesses by creating software that enables banks to access a deeper layer of technology, to find more lending opportunities and provide better lending solutions. David received his MBA from Emory’s Goizueta Business School.",
    },
    // {
    //   id: "jonBurke",
    //   name: "Jon Burke",
    //   title: "The Board",
    //   photo: jonHeadshot,
    //   bio: "Jon founded and built one of the country’s top-ranked advisory firms – Burke Capital. It became the preeminent bank advisory firm for nearly fifteen years in the Southeast, being well known as having unequaled access to most of the leaders of the banking industry. Prior to founding Burke Capital, Jon was a senior officer at The Robinson Humphrey Company, where he led the banking and financial institution research effort.\nJon played a critical role in assisting Robinson Humphrey’s various commercial banking offerings including corporate finance and trading. He was particularly known for assisting in bank M&A and capital assignments. Jon is considered one of the leading authorities on banking and financial institutions in the Southeast largely due to his role as advisor on key transactions in the market. Jon is a CFA and holds a BA in Business Administration from the University of Georgia and an MBA from Georgia State University.",
    // },
    {
      id: "andeNoktes",
      name: "Ande Noktes",
      title: "The Board",
      photo: andeHeadshot,
      bio: "Ande is an education professional with over 20 years of experience in international education. Currently, Ande is the Head of School of the Midtown International School, a school that she founded in 2012. Prior to starting Midtown International School, Ande was the Executive Director of International Preschools in Atlanta, which she also founded. During her tenure, she grew enrollment from 30 to over 250 students across two campuses. She led all fiscal and strategic planning activities, including the expansion and construction of the second campus. Prior to this, Ande was the Program Coordinator of Berlitz Language School in Bangkok, Thailand, where she was responsible for teacher development, program design and scheduling, enrollment, assessments, and class placement. Additionally, she launched a new kids program for schools through Southeast Asia. Ande was also a Lecturer at Assumption University in Bangkok. Ande received her Master of Arts degree in Philosophy from Assumption University in Bangkok, and her Bachelor of Arts in Liberal Arts from Colorado State University. Ande has a Masters in Business Administration from Emory University, and she has completed doctoral work in education with dissertation research focusing on executive function development in gifted learners.",
    },
    {
      id: "craigNazzaro",
      name: "Craig Nazzaro",
      title: "The Board",
      photo: craigHeadshot,
      bio: "Experienced Counsel with a demonstrated history of representing the financial services industry on all issues related to regulatory and compliance as well as data privacy. Craig Nazzaro is a member of the Nelson Mullins Financial Regulatory and fintech team and advises a variety of entities on all regulatory and compliance issues that impact the financial services industry including banks, non-bank lenders, servicers, investors, third-party payment processors, and debt collectors. He also defends clients against charges of liability and regulatory violations. Nazzaro’s prior experience includes serving as a vice president and assistant general counsel with J.P. Morgan Chase, where he managed and coordinated a team of over 20 senior legal officers and attorneys in responding to and resolving consumer lending issues presented by state attorneys generals, Housing and Urban Development, Consumer Financial Protection Bureau, Office of the Comptroller of the Currency, state banking departments, and Congressional inquiries. He also served as the Chief Compliance Officer for Chemical Bank, a $23 billion bank headquartered in Michigan from 2018 until 2019 when Chemical Bank merged with TCF National Bank. Mr. Nazzaro brings an in-house counsel mindset to all his client matters, and he approaches each matter from a business perspective.",
    },
    {
      id: "charlesWagner",
      name: "Charles Wagner",
      title: "The Board",
      photo: charlesHeadshot,
      bio: "Charles Wagner is currently the Chairman, CEO, President of United Trust Bank located in Palos Heights, IL. Prior to United Trust Bank, Mr. Wagner was President of CBC National Bank (Ticker: CBCO) which successfully sold in April 2018 to First Federal Bank of Florida. At CBC National Bank, Mr. Wagner built a robust Mortgage Banking operation from 2007 - 2015 before being named President. He was an active member of the Strategic Planning Committee, Executive Loan Committee, ALCO, and Compliance Committee. Additionally, he joined the Board of Directors in 2015. Prior to CBC National Bank, Mr. Wagner primarily held roles in Credit and/ or Executive Management of American Mortgage Express, SouthStar Funding, HomeBanc Mortgage Corporation, and Merrill Lynch Credit Corporation. Mr. Wagner graduated from the University of Georgia with a Bachelor’s Degree in Finance.",
    },
    {
      id: "jeffreyAtkisson",
      name: "Jeffrey Atkisson",
      title: "The Board",
      photo: jeffreyHeadshot,
      bio: "Jeff is a co-founder of multiple well known companies in his illustrious career including Regal Cinemas, AmStar Cinemas, and Pediatric Homecare of America. Prior to his entreprenurial journey, he worked as an executive in the venture capital and private equity arms of commercial banks NBD and First Tennessee. He started his career as a project analyst and engineer in the development arm of Ashland Oil. Jeff received his graduate degree from the University of Manchester in Industrial Robotics & International Business. ",
    }, 
        {
      id: "jamesGiuliani",
      name: "James Giuliani",
      title: "The Board",
      photo: jamesHeadshot,
      bio: "James Giuliani is currently the Chief Accounting Officer at SoFi. He has over 16 years of diversified financial services experience, with a significant time spent in the fintech space. Mr. Giuliani began his professional career at EY and has since held various accounting and finance responsibilities for three separate public companies. He is a CPA, CFP(r) and CFA Charterholder. He holds a BBA from Emory University and MBA from the University of Chicago Booth School of Business.",
    }, 
  ];

  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Modal
        show={Boolean(modalOpen)}
        onHide={() => {
          setModalOpen("");
        }}
        onExiting={() => {
          window.scrollBy({ top: 0 });
        }}
        onExited={() => {
          window.scrollBy({ top: 0 });
        }}
        onExit={() => {
          window.scrollBy({ top: 0 });
        }}
        centered
      >
        {lib
          .filter((bio) => bio.id === modalOpen)
          .map((filteredBio) => {
            return (
              <Modal.Body>
                <Modal.Header
                  closeButton
                  style={{ border: "none", padding: 0 }}
                />
                <Row>
                  <div className="text-center mb-4">
                    <img
                      src={filteredBio.photo}
                      alt={`${filteredBio.name}'s Headshot`}
                      height="100px"
                      width="100px"
                      style={{
                        borderRadius: "50%",
                        height: "200px",
                        width: "200px",
                      }}
                    />
                  </div>
                  <div>
                    <Typography type="subject">{filteredBio.name}</Typography>
                    <Typography type="paragraph">{filteredBio.bio}</Typography>
                  </div>
                </Row>
              </Modal.Body>
            );
          })}
      </Modal>
      <Section size="md" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Container className="text-center">
            <Typography type="head" className="text-center">
              Our Team
            </Typography>
            <hr style={{ height: "3px" }} className="mx-auto mt-4" />
            <Typography type="subtitle" className="mb-5">
              Get To Know The People That Made It All Happen
            </Typography>
          </Container>
        </Parallax>
      </Section>
      <Section size="lg" bg="dark" color="light">
        <div className="py-5">
          <Typography type="title" className="text-center">
            The Board of Directors
          </Typography>
          <Container>
            <Row>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("davidGe")}
              >
                <img
                  className="img-headshot"
                  style={{ width: 200 }}
                  src={davidPhoto}
                  alt="David Ge Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  David Ge
                </Typography>
                <Typography type="subject">CEO</Typography>
              </Col>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("andeNoktes")}
              >
                <img
                  className="img-headshot"
                  style={{ width: 200 }}
                  src={andePhoto}
                  alt="Ande Noktes Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  Ande Noktes
                </Typography>
                <Typography type="subject">The Board</Typography>
              </Col>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("craigNazzaro")}
              >
                <img
                  className="img-headshot"
                  style={{ width: 200 }}
                  src={craigPhoto}
                  alt="Craig Nazzaro Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  Craig Nazzaro
                </Typography>
                <Typography type="subject">The Board</Typography>
              </Col>
            </Row>
            <Row>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("charlesWagner")}
              >
                <img
                  className="img-headshot"
                  style={{ width: 200 }}
                  src={charlesPhoto}
                  alt="Charles Wagner Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  Charles Wagner
                </Typography>
                <Typography type="subject">The Board</Typography>
              </Col>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("jeffreyAtkisson")}
              >
                <img
                  className="img-headshot"
                  style={{ width: 200 }}
                  src={jeffreyPhoto}
                  alt="Jeffrey Atkisson Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  Jeffrey Atkisson
                </Typography>
                <Typography type="subject">The Board</Typography>
              </Col>
              <Col
                className="text-center py-4"
                onClick={() => setModalOpen("jamesGiuliani")}
              >
                <img
                  className="img-headshot"
                  style={{objectFit:'cover', width:191,height:253 }}
                  src={jamesHeadshot}
                  alt="James Giuliani Headshot"
                />
                <Typography type="subtitle" className="fw-bold">
                  James Giuliani
                </Typography>
                <Typography type="subject">The Board</Typography>
              </Col>
            </Row>
          </Container>
        </div>
      </Section>
    </Layout>
  );
};

export default LoanOfficers;
